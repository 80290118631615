<template>
  <b-card no-body class="card-standard plans-card" style="cursor: auto">
    <b-card-body class="flex-column align-items-center justify-content-center">
      <!-- Element Name -->
      <div v-if="discount" class="flag d-flex flex-column align-items-center">
        <p class="m-0 text-white font-small-2">
          <span v-if="discount.discount > 0">{{ discount.discount }}</span>
          <span v-else>0</span>
          <span v-if="discount.isPercentage">%</span>
          <span v-else>
            <span v-if="currency">{{ currency.symbol }}</span>
            <span v-else>{{ item.currencySymbol }}</span>
          </span>
          OFF
        </p>
      </div>
      <!-- item name -->
      <h2
        v-if="translate(item.name)"
        class="mb-25 mt-1 text-center title min-height-4"
      >
        {{ translate(item.name) }}
      </h2>
      <h2 v-else class="mb-2 mt-1 text-center title text-muted">
        {{ $t("backoffice.subscriptions.insert-title") }}
      </h2>
      <!-- price -->
      <div
        class="text-primary d-flex align-items-center justify-content-center price-container"
      >
        <text-resizable
          :text-style="'text-primary text-center h5 mb-0'"
          :text="total(item)"
          :default-font-size="3.2"
          :default-text-length="7"
          :font-size-to-rest="0.38"
        />
        <div div class="d-flex ml-50 flex-column">
          <small v-if="item.interval === 'month'">
            /
            <span v-if="item.intervalCount && item.intervalCount > 1">
              {{ item.intervalCount }}
              {{ $t("onboarding.subscriptions.months") }}
            </span>
            <span v-else>{{ $t("onboarding.subscriptions.month") }}
            </span>
          </small>
          <small v-if="item.interval === 'year'">
            / {{ $t("onboarding.subscriptions.year") }}
          </small>
        </div>
      </div>
      <!-- discount -->
      <div
        v-if="discount" class="d-flex min-height-1 align-items-center justify-content-center">
        <p class="text-muted mb-0">
          PVR:
          <span style="text-decoration: line-through">
            {{ item.total }} 
            <span v-if="currency">{{ currency.symbol }}</span>
            <span v-else>{{ item.currencySymbol }}</span>
          </span>
        </p>
      </div>
      <!-- button -->
      <div class="w-100 mt-1 d-flex justify-content-between">
        <b-button
          class="mx-3 my-1 w-100"
          variant="primary"
          @click="fromBackoffice ? '' : goToOrderPurchase(item)"
        >
          {{ $t("onboarding.subscriptions.select") }}
        </b-button>
      </div>
      <!-- description and items included -->
      <div
        v-if="item.description && Object.values(item.description).length > 0"
        class="min-height-5"
      >
        <p class="text-muted small mb-25" :class="discount ? 'mt-25' : 'mt-2'">
          {{ $t("onboarding.subscriptions.includes") }}:
        </p>
        <div
          v-for="(benefit, index) in translate(item.description).split('\n')"
          :key="index"
          class="d-flex"
        >
          <b-icon-check2
            variant="primary"
            font-scale="0.8"
            class="mr-50 check mt-25"
          />
          <div class="text-primary font-small-2 m-0">
            {{ benefit }}
          </div>
        </div>
      </div>
      <div v-else class="min-height-5">
        <p />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import TextResizable from "@/backoffice/components/TextResizable.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { totalCard } from "@/store/collective/plans-helpers";

export default {
  name: "PlansCard",
  components: {
    TextResizable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    discount: {
      type: Object,
      default: null,
    },
    currency: {
      type: Object,
      default: null,
    },
    language: {
      type: String,
      default: null,
    },
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    collectiveSlug() {
      return this.$route.params.communityId;
    },
    fromBackoffice() {
      return this.$route.path.includes("backoffice");
    },
  },
  methods: {
    translate(field) {
      return translateTranslationTable(
        this.language || this.currentLocale,
        field
      );
    },
    total(item) {
      return totalCard(item, this.discount, this.currency);
    },
    goToOrderPurchase(item) {
      const url = {
        name: "onboarding-checkout",
        params: {
          communityId: this.collectiveSlug,
          type: item.namespace,
          id: item.key,
        },
        query: {},
      };
      if (this.discount) {
        url.query.discount = this.discount.key;
      }
      return this.$router.replace(url);
    },
  },
};
</script>

<style>
.plans-card {
  min-height: 420px !important;
  overflow: scroll;
  width: 320px;
  filter: drop-shadow(0 5px 10px rgba(0,0,0,.1));
  vertical-align: top;
  white-space: normal;
}
</style>